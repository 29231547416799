/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import {
  map,
  get,
  filter,
  groupBy,
  entries,
  reverse,
  cloneDeep,
  isFinite,
} from 'lodash';

export const typeTypes = {
  ODDS: 'odds',
  odds: 'ODDS',
  STAKE: 'stake',
  stake: 'STAKE',
  POTENTIAL_PAYOUT: 'potentialPayout',
  potentialPayout: 'POTENTIAL_PAYOUT',
  FREE_STAKE: 'freeStake',
  freeStake: 'FREE_STAKE',
};

export const typeOptions = {
  [typeTypes.ODDS]: {
    id: typeTypes.ODDS,
    label: 'Odds',
    field: 'odds.decimalValue',
  },
  [typeTypes.STAKE]: {
    id: typeTypes.STAKE,
    label: 'Stake',
    field: 'stake',
  },
  [typeTypes.POTENTIAL_PAYOUT]: {
    id: typeTypes.POTENTIAL_PAYOUT,
    label: 'Potential payout',
    field: 'potentialPayout',
  },
  [typeTypes.FREE_STAKE]: {
    id: typeTypes.FREE_STAKE,
    label: 'Free stake',
    field: 'freeStake',
  },
};

export const textColorTypes = {
  DARK: 'DARK',
  LIGHT: 'LIGHT',
};

export const textColorOptions = {
  [textColorTypes.DARK]: {
    id: textColorTypes.DARK,
    label: 'Dark',
    value: '#000',
  },
  [textColorTypes.LIGHT]: {
    id: textColorTypes.LIGHT,
    label: 'Light',
    value: '#fff',
  },
};

export const highlightTypeTypes = {
  CELL: 'CELL',
  ROW: 'ROW',
};

export const highlightTypeOptions = {
  [highlightTypeTypes.CELL]: {
    id: highlightTypeTypes.CELL,
    label: 'Cell',
  },
  [highlightTypeTypes.ROW]: {
    id: highlightTypeTypes.ROW,
    label: 'Row',
  },
};

export const conditionTypeTypes = {
  LESS_THAN: 'LESS_THAN',
  LESS_OR_EQUAL: 'LESS_OR_EQUAL',
  EQUAL: 'EQUAL',
  GREATER_OR_EQUAL: 'GREATER_OR_EQUAL',
  GREATER_THAN: 'GREATER_THAN',
};

export const conditionTypeOptions = {
  [conditionTypeTypes.EQUAL]: {
    id: conditionTypeTypes.EQUAL,
    label: 'Equals (=)',
  },
  [conditionTypeTypes.LESS_THAN]: {
    id: conditionTypeTypes.LESS_THAN,
    label: 'Less than (<)',
  },
  [conditionTypeTypes.LESS_OR_EQUAL]: {
    id: conditionTypeTypes.LESS_OR_EQUAL,
    label: 'Less than or equal to (<=)',
  },
  [conditionTypeTypes.GREATER_THAN]: {
    id: conditionTypeTypes.GREATER_THAN,
    label: 'Greater than (>)',
  },
  [conditionTypeTypes.GREATER_OR_EQUAL]: {
    id: conditionTypeTypes.GREATER_OR_EQUAL,
    label: 'Greater than or equal to (>=)',
  },
};

export const filterSupportedBetHighlights = (highlights) => filter(highlights, ({ type }) => !!typeTypes[type]);

export const getComparator = (conditionType) => {
  switch (conditionType) {
  case conditionTypeTypes.LESS_THAN:
    return (a, b) => {
      if (!isFinite(a) || !isFinite(b)) return 0;
      return +a.toFixed(2) < +b.toFixed(2);
    };
  case conditionTypeTypes.LESS_OR_EQUAL:
    return (a, b) => {
      if (!isFinite(a) || !isFinite(b)) return 0;
      return +a.toFixed(2) <= +b.toFixed(2);
    };
  case conditionTypeTypes.EQUAL:
    return (a, b) => {
      if (!isFinite(a) || !isFinite(b)) return 0;
      return +a.toFixed(2) === +b.toFixed(2);
    };
  case conditionTypeTypes.GREATER_OR_EQUAL:
    return (a, b) => {
      if (!isFinite(a) || !isFinite(b)) return 0;
      return +a.toFixed(2) >= +b.toFixed(2);
    };
  case conditionTypeTypes.GREATER_THAN:
    return (a, b) => {
      if (!isFinite(a) || !isFinite(b)) return 0;
      return +a.toFixed(2) > +b.toFixed(2);
    };
  default:
    console.warn(`No comparator for "${conditionType}" condition type`);
    return () => false;
  }
};

export const formatConditionType = (conditionType) => {
  switch (conditionType) {
  case conditionTypeTypes.LESS_THAN:
    return '<';
  case conditionTypeTypes.LESS_OR_EQUAL:
    return '<=';
  case conditionTypeTypes.EQUAL:
    return '=';
  case conditionTypeTypes.GREATER_OR_EQUAL:
    return '>=';
  case conditionTypeTypes.GREATER_THAN:
    return '>';
  default:
    return '?';
  }
};

export const formatParameter = (parameter) => `"${parameter}"`;

export const formatConditionTypeAndParameter = (condtitionType, parameter) => {
  const { label = 'N/A' } = conditionTypeOptions[condtitionType] || {};
  const formattedParameter = formatParameter(parameter);
  return `${label} ${formattedParameter}`;
};

export const mapClashingHighlights = (highlights) => {
  const mappedHighlights = cloneDeep(highlights);

  for (const highlight of mappedHighlights) {
    highlight.clash = false;
  }

  for (let highlightIndexA = 0; highlightIndexA < mappedHighlights.length - 1; highlightIndexA += 1) {
    const highlightA = mappedHighlights[highlightIndexA];
    if (highlightA.highlightType !== 'CELL') continue;

    for (let highlightIndexB = 1; highlightIndexB < mappedHighlights.length; highlightIndexB += 1) {
      const highlightB = mappedHighlights[highlightIndexB];

      if (
        highlightA.index === highlightB.index
        || highlightA.type !== highlightB.type
        || highlightB.highlightType !== 'CELL'
      ) continue;

      if (
        highlightA.conditionType === conditionTypeTypes.GREATER_THAN
        && highlightB.conditionType === conditionTypeTypes.GREATER_THAN
        && +highlightA.parameter <= +highlightB.parameter
      ) {
        highlightB.clash = true;
      }

      if (
        highlightA.conditionType === conditionTypeTypes.GREATER_OR_EQUAL
        && highlightB.conditionType === conditionTypeTypes.GREATER_THAN
        && +highlightA.parameter <= +highlightB.parameter
      ) {
        highlightB.clash = true;
      }

      if (
        highlightA.conditionType === conditionTypeTypes.GREATER_THAN
        && highlightB.conditionType === conditionTypeTypes.GREATER_OR_EQUAL
        && +highlightA.parameter <= +highlightB.parameter
      ) {
        highlightB.clash = true;
      }

      if (
        highlightA.conditionType === conditionTypeTypes.LESS_THAN
        && highlightB.conditionType === conditionTypeTypes.LESS_THAN
        && +highlightA.parameter <= +highlightB.parameter
      ) {
        highlightB.clash = true;
      }

      if (
        highlightA.conditionType === conditionTypeTypes.LESS_OR_EQUAL
        && highlightB.conditionType === conditionTypeTypes.LESS_THAN
        && +highlightA.parameter <= +highlightB.parameter
      ) {
        highlightB.clash = true;
      }

      if (
        highlightA.conditionType === conditionTypeTypes.LESS_THAN
        && highlightB.conditionType === conditionTypeTypes.LESS_OR_EQUAL
        && +highlightA.parameter <= +highlightB.parameter
      ) {
        highlightB.clash = true;
      }

      if (
        highlightA.conditionType === conditionTypeTypes.EQUAL
        && highlightB.conditionType === conditionTypeTypes.EQUAL
        && +highlightA.parameter === +highlightB.parameter
      ) {
        highlightB.clash = true;
      }

      if (
        highlightA.conditionType === conditionTypeTypes.EQUAL
        && highlightB.conditionType === conditionTypeTypes.GREATER_OR_EQUAL
        && +highlightA.parameter >= +highlightB.parameter
      ) {
        highlightA.clash = true;
      }

      if (
        highlightA.conditionType === conditionTypeTypes.GREATER_OR_EQUAL
        && highlightB.conditionType === conditionTypeTypes.EQUAL
        && +highlightA.parameter <= +highlightB.parameter
      ) {
        highlightA.clash = true;
      }

      if (
        highlightA.conditionType === conditionTypeTypes.EQUAL
        && highlightB.conditionType === conditionTypeTypes.LESS_OR_EQUAL
        && +highlightA.parameter <= +highlightB.parameter
      ) {
        highlightA.clash = true;
      }

      if (
        highlightA.conditionType === conditionTypeTypes.LESS_OR_EQUAL
        && highlightB.conditionType === conditionTypeTypes.EQUAL
        && +highlightA.parameter >= +highlightB.parameter
      ) {
        highlightA.clash = true;
      }
    }
  }

  return mappedHighlights;
};

export const parseBetHighlightOdds = (value, oddsFormat = 'american') => {
  switch (oddsFormat) {
  case 'american':
    return {
      originalFormattedPrice: {
        priceType: 'american',
        signIsPlus: value >= 0,
        value,
      },
      format: 'ORIGINAL_FORMATTED_PRICE',
    };
  case 'decimal':
    return {
      decimalPrice: value,
      format: 'DECIMAL_PRICE',
    };
  case 'probability':
    return {
      probability: value,
      format: 'PROBABILITY',
    };
  default:
    return value;
  }
};

export const composeBetHighlightOdds = (odds, oddsFormat = 'american') => {
  switch (oddsFormat) {
  case 'american':
    const obj = odds?.originalFormattedPrice || odds?.originalFormattedValue;
    if (!isFinite(obj?.value)) return '';
    const factor = obj.signIsPlus ? 1 : -1;
    return obj.value * factor;
  case 'decimal':
    const val = odds?.decimalPrice || odds?.decimalValue;
    if (!isFinite(val)) return '';
    return val;
  case 'probability':
    if (!isFinite(odds?.probability)) return '';
    return odds?.probability;
  default:
    return '';
  }
};

export const parseBetHighlightParameter = (type, value, oddsFormat = 'american') => {
  switch (type) {
  case 'odds':
    return parseBetHighlightOdds(value, oddsFormat);
  default:
    return value;
  }
};

export const composeBetHighlightParameter = (type, parameter, oddsFormat = 'american') => {
  switch (type) {
  case 'odds':
    return composeBetHighlightOdds(parameter, oddsFormat);
  default:
    return parameter;
  }
};

export const parseBetParameter = (type, value, oddsFormat = 'american') => {
  switch (type) {
  case 'odds':
    return parseBetHighlightOdds(value, oddsFormat);
  default:
    return +value;
  }
};

export const composeBetParameter = (type, field, bet, oddsFormat = 'american') => {
  switch (type) {
  case 'odds':
    return composeBetHighlightOdds(bet.odds, oddsFormat);
  default:
    return +get(bet, field);
  }
};

export const getBetHighlightDefaultParameter = (type, oddsFormat = 'american') => {
  if (!type) return '';
  switch (type) {
  case 'odds':
    return composeBetHighlightOdds(null, oddsFormat);
  default:
    return '';
  }
};

export const isBetHighlightParameterValid = (type, parameter, oddsFormat = 'american') => {
  switch (type) {
  case 'odds':
    return isFinite(composeBetHighlightOdds(parameter, oddsFormat));
  default:
    return isFinite(parameter);
  }
};

export const formatBetHighlight = (betHighlight, oddsFormat = 'american') => {
  const { label = 'N/A' } = typeOptions[betHighlight.type] || {};
  const formattedCondtitionType = formatConditionType(betHighlight.conditionType);
  const formattedParameter = composeBetHighlightParameter(betHighlight.type, betHighlight.parameter, oddsFormat);
  return `${label} ${formattedCondtitionType} ${formattedParameter}`;
};

export const getMatchedHighlights = (bet, { rowHighlights, cellHighlights, oddsFormat = 'american' }) => {
  const matchedHighlights = {};

  for (const highlight of rowHighlights) {
    const options = typeOptions[highlight.type];
    const comparator = getComparator(highlight.conditionType);

    const betValue = composeBetParameter(options.id, options.field, bet, oddsFormat);
    const parameterValue = composeBetHighlightParameter(highlight.type, highlight.parameter, oddsFormat);
    if (comparator(betValue, parameterValue)) {
      matchedHighlights.row = {
        backgroundColor: highlight.color,
        color: highlight.textColor === 'LIGHT' ? '#FFF' : '#000',
      };
      break;
    }
  }

  const groupedCellHighlights = groupBy(cellHighlights, 'type');
  for (const [type, highlights] of entries(groupedCellHighlights)) {
    const options = typeOptions[type];
    for (const highlight of reverse(highlights)) {
      const comparator = getComparator(highlight.conditionType);
      const betValue = composeBetParameter(options.id, options.field, bet, oddsFormat);
      const parameterValue = composeBetHighlightParameter(highlight.type, highlight.parameter, oddsFormat);
      if (comparator(betValue, parameterValue)) {
        matchedHighlights[options.id] = {
          backgroundColor: highlight.color,
          color: highlight.textColor === 'LIGHT' ? '#FFF' : '#000',
        };
      }
    }
  }

  return matchedHighlights;
};

export const mapMatchedHighlights = (bets, options) => map(bets, (bet) => {
  const highlights = getMatchedHighlights(bet, options);
  return { ...bet, highlights };
});
