import {
  map,
  includes,
  indexOf,
  cloneDeep,
  assign,
  keys,
} from 'lodash';
import { formatMinutesAndSeconds } from '@/utils/number';
import Event from './event';

export const ID = '841e188b-0dcf-4f5f-974f-aa52c8cec95b';

const Periods = {
  N_A: 'N_A',
  IN_FIRST_PERIOD: 'IN_FIRST_PERIOD',
  IN_SECOND_PERIOD: 'IN_SECOND_PERIOD',
  AT_HALF_TIME: 'AT_HALF_TIME',
  IN_THIRD_PERIOD: 'IN_THIRD_PERIOD',
  IN_FOURTH_PERIOD: 'IN_FOURTH_PERIOD',
  NORMAL_TIME_COMPLETED: 'NORMAL_TIME_COMPLETED',
  IN_OVERTIME: 'IN_OVERTIME',
  OVER_TIME_COMPLETED: 'OVER_TIME_COMPLETED',
  EVENT_COMPLETED: 'EVENT_COMPLETED',
};

const mustHavePeriods = [
  Periods.IN_FIRST_PERIOD,
  Periods.IN_SECOND_PERIOD,
  Periods.IN_THIRD_PERIOD,
  Periods.IN_FOURTH_PERIOD,
];

const activePeriods = [
  Periods.IN_FIRST_PERIOD,
  Periods.IN_SECOND_PERIOD,
  Periods.IN_THIRD_PERIOD,
  Periods.IN_FOURTH_PERIOD,
  Periods.IN_OVERTIME,
];

const livePeriods = [
  Periods.IN_FIRST_PERIOD,
  Periods.IN_SECOND_PERIOD,
  Periods.AT_HALF_TIME,
  Periods.IN_THIRD_PERIOD,
  Periods.IN_FOURTH_PERIOD,
  Periods.NORMAL_TIME_COMPLETED,
  Periods.IN_OVERTIME,
];

const getLivePeriods = () => [...livePeriods];

const PeriodLabels = {
  TOTAL: 'Match',
  [Periods.N_A]: 'N/A',
  [Periods.IN_FIRST_PERIOD]: 'Q1',
  [Periods.IN_SECOND_PERIOD]: 'Q2',
  [Periods.AT_HALF_TIME]: 'Break',
  [Periods.IN_THIRD_PERIOD]: 'Q3',
  [Periods.IN_FOURTH_PERIOD]: 'Q4',
  [Periods.NORMAL_TIME_COMPLETED]: 'Break',
  [Periods.IN_OVERTIME]: 'OT',
  [Periods.EVENT_COMPLETED]: 'Finished',
  PERIOD_1: 'Q1',
  PERIOD_2: 'Q2',
  PERIOD_3: 'Q3',
  PERIOD_4: 'Q4',
  OVERTIME: 'OT',
};

const PeriodAbbreviations = {
  TOTAL: 'Match',
  [Periods.N_A]: 'N/A',
  [Periods.IN_FIRST_PERIOD]: 'Q1',
  [Periods.IN_SECOND_PERIOD]: 'Q2',
  [Periods.AT_HALF_TIME]: 'Break',
  [Periods.IN_THIRD_PERIOD]: 'Q3',
  [Periods.IN_FOURTH_PERIOD]: 'Q4',
  [Periods.NORMAL_TIME_COMPLETED]: 'Break',
  [Periods.IN_OVERTIME]: 'OT',
  [Periods.EVENT_COMPLETED]: 'Finished',
  PERIOD_1: 'Q1',
  PERIOD_2: 'Q2',
  PERIOD_3: 'Q3',
  PERIOD_4: 'Q4',
  OVERTIME: 'OT',
};

const isPrematch = (event) => Event.isPrematch(event) || event.period === Periods.N_A;

const isLive = (event) => Event.isLive(event) && event.period !== Periods.EVENT_COMPLETED;

const getPeriodTime = ({
  period,
  gameTimeElapsedInPeriod,
  quarterDuration,
  overtimeDuration,
}) => {
  const totalDurationInSeconds = (period === 'IN_OVERTIME' ? overtimeDuration : quarterDuration);
  const currentTimeInSeconds = totalDurationInSeconds - gameTimeElapsedInPeriod - 1;
  return formatMinutesAndSeconds(currentTimeInSeconds);
};

const getPeriodLabel = ({
  period,
  gameTimeElapsedInPeriod,
  quarterDuration,
  overtimeDuration,
}) => {
  const label = PeriodLabels[period] || '';
  if (!includes(activePeriods, period)) return label;

  const time = getPeriodTime({
    period,
    gameTimeElapsedInPeriod,
    quarterDuration,
    overtimeDuration,
  });

  return `${label} · ${time}`;
};

const getPeriodValue = (periodCode) => {
  if (periodCode === 'TOTAL') return 0;
  return indexOf(keys(Periods), periodCode) + 1;
};

const getDetails = (event, prematch) => {
  const scorePerPeriod = map(
    Event.getAvailablePeriods({
      allPeriods: keys(Periods),
      scorePerPeriod: event.home.scorePerPeriod,
      mustHavePeriods: [
        Periods.IN_FIRST_PERIOD,
        Periods.IN_SECOND_PERIOD,
        Periods.IN_THIRD_PERIOD,
        Periods.IN_FOURTH_PERIOD,
      ],
    }),
    (period) => {
      const isVisible = !prematch && getPeriodValue(event.period) >= getPeriodValue(period);
      return {
        code: period,
        label: PeriodAbbreviations[period],
        homeValue: isVisible ? event.home.scorePerPeriod[period] || 0 : '',
        awayValue: isVisible ? event.away.scorePerPeriod[period] || 0 : '',
      };
    },
  );

  return [
    {
      code: 'TOTAL',
      label: PeriodAbbreviations.TOTAL,
      homeValue: prematch ? '-' : event.home.score,
      awayValue: prematch ? '-' : event.away.score,
    },
    ...scorePerPeriod,
  ];
};

const updateEvent = (eventDetails, payload) => {
  const updatedEventDetails = cloneDeep(eventDetails);

  assign(
    updatedEventDetails,
    {
      ...updatedEventDetails,
      period: payload.state.period,
      teamInPossession: payload.state.inPossession,
      gameTimeElapsedInPeriod: payload.state.gameTimeElapsedInPeriod,
      quarterDuration: payload.rules.quarterDuration,
      overtimeDuration: payload.rules.overtimeDuration,
      overtimePossible: payload.rules.overtimePossible,
      homeScore: payload.state.homeScore,
      homeScoreFirstQuarter: payload.state.homeScoreFirstQuarter,
      homeScoreSecondQuarter: payload.state.homeScoreSecondQuarter,
      homeScoreThirdQuarter: payload.state.homeScoreThirdQuarter,
      homeScoreFourthQuarter: payload.state.homeScoreFourthQuarter,
      homeScoreOvertime: payload.state.homeScoreOverTime,
      awayScore: payload.state.awayScore,
      awayScoreFirstQuarter: payload.state.awayScoreFirstQuarter,
      awayScoreSecondQuarter: payload.state.awayScoreSecondQuarter,
      awayScoreThirdQuarter: payload.state.awayScoreThirdQuarter,
      awayScoreFourthQuarter: payload.state.awayScoreFourthQuarter,
      awayScoreOvertime: payload.state.awayScoreOverTime,
      drives: payload.state.drives,
      downNumber: payload.state.downNumber,
      yardsToEndZone: payload.state.yardsToEndZone,
      yardsToWinDown: payload.state.yardsToWinDown,
      isPlayRunning: payload.state.isPlayRunning,
      homeLineup: payload.competitors?.homeTeam?.lineup,
      awayLineup: payload.competitors?.awayTeam?.lineup,
      eventInBreak: payload.state.eventInBreak,
      eventPreMatch: payload.state.eventPreMatch,
      riskFlags: payload.state.riskFlags,
      awayTimeoutsLeft: payload.state.awayTimeOutsLeft,
      homeTimeoutsLeft: payload.state.homeTimeOutsLeft,
    },
  );

  return updatedEventDetails;
};

const formatPeriodTime = ({
  period,
  gameTimeElapsedInPeriod,
  overtimeDuration,
  quarterDuration,
}) => {
  const totalDurationInSeconds = (period === 'IN_OVERTIME' ? overtimeDuration : quarterDuration);
  const currentTimeInSeconds = totalDurationInSeconds - gameTimeElapsedInPeriod - 1;
  return formatMinutesAndSeconds(currentTimeInSeconds);
};

const formatPeriodMinute = ({
  period,
  gameTimeElapsedInPeriod,
  overtimeDuration,
  quarterDuration,
}) => {
  const totalDurationInSeconds = (period === 'IN_OVERTIME' ? overtimeDuration : quarterDuration);
  const currentTimeInSeconds = totalDurationInSeconds - gameTimeElapsedInPeriod - 1;
  return formatMinutesAndSeconds(currentTimeInSeconds);
};

const formatPeriodLabel = ({ period }) => PeriodLabels[period] ?? period;

const formatPeriodAbbreviation = ({ period }) => PeriodAbbreviations[period] ?? period;

const formatPeriod = (details, options) => {
  const formatLabel = options?.formatLabel ?? formatPeriodLabel;
  const formatTime = options?.formatTime ?? formatPeriodMinute;
  const separator = options?.separator ?? '·';

  const label = formatLabel(details);
  const time = formatTime(details);

  return (includes(activePeriods, details.period) ? `${label} ${separator} ${time}` : label);
};

const calculatePeriodProgress = ({
  period,
  gameTimeElapsedInPeriod,
  overtimeDuration,
  quarterDuration,
}) => {
  if (!includes(activePeriods, period)) return -1;
  if (includes(PeriodAbbreviations[period], 'OT')) return (gameTimeElapsedInPeriod / overtimeDuration) * 100;
  return (gameTimeElapsedInPeriod / quarterDuration) * 100;
};

const getAvailablePeriods = (event) => [
  {
    id: 'TOTAL',
    label: 'Full event',
  },
  ...map(
    Event.getAvailablePeriods({
      allPeriods: keys(Periods),
      scorePerPeriod: event.home.scorePerPeriod,
      mustHavePeriods: [
        Periods.IN_FIRST_PERIOD,
        Periods.IN_SECOND_PERIOD,
        Periods.IN_THIRD_PERIOD,
        Periods.IN_FOURTH_PERIOD,
      ],
    }),
    (period) => ({
      id: period,
      label: PeriodLabels[period],
    }),
  ),
];

export const createPlayerStatisticsColumns = () => ({
  passAttempts: {
    label: 'PA',
    tooltip: 'Pass attempts',
    field: 'stats.passAttempts',
    style: {
      'text-align': 'right',
    },
  },
  passCompletions: {
    label: 'PC',
    tooltip: 'Pass completions',
    field: 'stats.passCompletions',
    style: {
      'text-align': 'right',
    },
  },
  receptions: {
    label: 'R',
    tooltip: 'Receptions',
    field: 'stats.receptions',
    style: {
      'text-align': 'right',
    },
  },
  receivingYards: {
    label: 'RecY',
    tooltip: 'Receiving yards',
    field: 'stats.receivingYards',
    style: {
      'text-align': 'right',
    },
  },
  rushingAttempts: {
    label: 'RA',
    tooltip: 'Rushing attempts',
    field: 'stats.rushingAttempts',
    style: {
      'text-align': 'right',
    },
  },
  rushingYards: {
    label: 'RY',
    tooltip: 'Rushing yards',
    field: 'stats.rushingYards',
    style: {
      'text-align': 'right',
    },
  },
  passingYards: {
    label: 'PY',
    tooltip: 'Passing yards',
    field: 'stats.passingYards',
    style: {
      'text-align': 'right',
    },
  },
  passingTouchdowns: {
    label: 'PT',
    tooltip: 'Passing touchdowns',
    field: 'stats.passingTouchdowns',
    style: {
      'text-align': 'right',
    },
  },
  touchdowns: {
    label: 'T',
    tooltip: 'Touchdowns',
    field: 'stats.touchdowns',
    style: {
      'text-align': 'right',
    },
  },
  touchdownYards: {
    label: 'YT',
    tooltip: 'Yards on touchdowns',
    field: 'stats.touchdownYards',
    style: {
      'text-align': 'right',
    },
  },
  interceptions: {
    label: 'I',
    tooltip: 'Interceptions',
    field: 'stats.interceptions',
    style: {
      'text-align': 'right',
    },
  },
});

export const createDriveOutcomeProbabilitiesColumns = () => ({
  safety: {
    label: 'SF',
    tooltip: '% of drives that end in safety',
    field: 'safety',
    showPercentage: true,
    style: {
      'text-align': 'right',
    },
  },
  halfTime: {
    label: 'EH',
    tooltip: '% of drives that end with the half-time',
    field: 'halfTime',
    showPercentage: true,
    style: {
      'text-align': 'right',
    },
  },
  turnover: {
    label: 'TO',
    tooltip: 'Turnover',
    field: 'turnover',
    showPercentage: false,
    style: {
      'text-align': 'right',
    },
  },
  missedFieldGoal: {
    label: 'MF',
    tooltip: 'Missed Field goal',
    field: 'missedFieldGoal',
    showPercentage: false,
    style: {
      'text-align': 'right',
    },
  },
  turnoverOnDowns: {
    label: 'DN',
    tooltip: 'Turnover on downs',
    field: 'turnoverOnDowns',
    showPercentage: false,
    style: {
      'text-align': 'right',
    },
  },
  touchdown: {
    label: 'TD',
    tooltip: 'Touchdown',
    field: 'touchdown',
    showPercentage: false,
    style: {
      'text-align': 'right',
    },
  },
  madeFieldGoal: {
    label: 'FG',
    tooltip: 'Made Field Goal',
    field: 'madeFieldGoal',
    showPercentage: false,
    style: {
      'text-align': 'right',
    },
  },
  punt: {
    label: 'PN',
    tooltip: 'Punt',
    field: 'punt',
    showPercentage: false,
    style: {
      'text-align': 'right',
    },
  },
  defensiveTouchdown: {
    label: 'DT',
    tooltip: 'Defensive Touchdown',
    field: 'defensiveTouchdown',
    showPercentage: false,
    style: {
      'text-align': 'right',
    },
  },
});

export default {
  ID,
  Periods,
  mustHavePeriods,
  activePeriods,
  PeriodLabels,
  PeriodAbbreviations,
  isPrematch,
  isLive,
  getPeriodTime,
  getPeriodLabel,
  getPeriodValue,
  getDetails,
  updateEvent,
  formatPeriodTime,
  formatPeriodMinute,
  formatPeriodLabel,
  formatPeriodAbbreviation,
  formatPeriod,
  calculatePeriodProgress,
  getAvailablePeriods,
  createPlayerStatisticsColumns,
  createDriveOutcomeProbabilitiesColumns,
  getLivePeriods,
};
