import {
  map,
  indexOf,
  cloneDeep,
  assign,
  keys,
  includes,
  isFinite,
} from 'lodash';
import { formatMinutesAndSeconds } from '@/utils/number';
import Event from './event';

export const ID = 'db5e8b75-30a3-4a97-9112-f28b8e962887';

const Periods = {
  PRE_GAME: 'PRE_GAME',
  FIRST_PERIOD: 'FIRST_PERIOD',
  BEFORE_SECOND_PERIOD: 'BEFORE_SECOND_PERIOD',
  SECOND_PERIOD: 'SECOND_PERIOD',
  BEFORE_THIRD_PERIOD: 'BEFORE_THIRD_PERIOD',
  THIRD_PERIOD: 'THIRD_PERIOD',
  BEFORE_OVERTIME: 'BEFORE_OVERTIME',
  OVERTIME_1: 'OVERTIME_1',
  OVERTIME_2: 'OVERTIME_2',
  OVERTIME_3: 'OVERTIME_3',
  OVERTIME_4: 'OVERTIME_4',
  OVERTIME_5: 'OVERTIME_5',
  OVERTIME_6: 'OVERTIME_6',
  OVERTIME_7: 'OVERTIME_7',
  OVERTIME_8: 'OVERTIME_8',
  OVERTIME_9: 'OVERTIME_9',
  OVERTIME_10: 'OVERTIME_10',
  BEFORE_PENALTY_SHOOTOUT: 'BEFORE_PENALTY_SHOOTOUT',
  PENALTY_SHOOTOUT: 'PENALTY_SHOOTOUT',
  POST_GAME: 'POST_GAME',
  GAME_ABANDONED: 'GAME_ABANDONED',
  SUSPENDED: 'SUSPENDED',
};

const mustHavePeriods = [
  Periods.FIRST_PERIOD,
  Periods.SECOND_PERIOD,
  Periods.THIRD_PERIOD,
];

const activePeriods = [
  Periods.FIRST_PERIOD,
  Periods.SECOND_PERIOD,
  Periods.THIRD_PERIOD,
  Periods.OVERTIME_1,
  Periods.OVERTIME_2,
  Periods.OVERTIME_3,
  Periods.OVERTIME_4,
  Periods.OVERTIME_5,
  Periods.OVERTIME_6,
  Periods.OVERTIME_7,
  Periods.OVERTIME_8,
  Periods.OVERTIME_9,
  Periods.OVERTIME_10,
  Periods.PENALTY_SHOOTOUT,
];

const PeriodLabels = {
  TOTAL: 'Match',
  [Periods.PRE_GAME]: 'Pregame',
  [Periods.FIRST_PERIOD]: 'P1',
  [Periods.BEFORE_SECOND_PERIOD]: 'Break',
  [Periods.SECOND_PERIOD]: 'P2',
  [Periods.BEFORE_THIRD_PERIOD]: 'Break',
  [Periods.THIRD_PERIOD]: 'P3',
  [Periods.BEFORE_OVERTIME]: 'Break',
  [Periods.OVERTIME_1]: 'OT',
  [Periods.OVERTIME_2]: 'OT2',
  [Periods.OVERTIME_3]: 'OT3',
  [Periods.OVERTIME_4]: 'OT4',
  [Periods.OVERTIME_5]: 'OT5',
  [Periods.OVERTIME_6]: 'OT6',
  [Periods.OVERTIME_7]: 'OT7',
  [Periods.OVERTIME_8]: 'OT8',
  [Periods.OVERTIME_9]: 'OT9',
  [Periods.OVERTIME_10]: 'OT10',
  [Periods.BEFORE_PENALTY_SHOOTOUT]: 'Break',
  [Periods.PENALTY_SHOOTOUT]: 'Penalty SO',
  [Periods.POST_GAME]: 'Finished',
  [Periods.GAME_ABANDONED]: 'Suspended',
};

const PeriodAbbreviations = {
  TOTAL: 'Match',
  [Periods.PRE_GAME]: 'Pregame',
  [Periods.FIRST_PERIOD]: 'P1',
  [Periods.BEFORE_SECOND_PERIOD]: 'Break',
  [Periods.SECOND_PERIOD]: 'P2',
  [Periods.BEFORE_THIRD_PERIOD]: 'Break',
  [Periods.THIRD_PERIOD]: 'P3',
  [Periods.BEFORE_OVERTIME]: 'Break',
  [Periods.OVERTIME_1]: 'OT',
  [Periods.OVERTIME_2]: 'OT2',
  [Periods.OVERTIME_3]: 'OT3',
  [Periods.OVERTIME_4]: 'OT4',
  [Periods.OVERTIME_5]: 'OT5',
  [Periods.OVERTIME_6]: 'OT6',
  [Periods.OVERTIME_7]: 'OT7',
  [Periods.OVERTIME_8]: 'OT8',
  [Periods.OVERTIME_9]: 'OT9',
  [Periods.OVERTIME_10]: 'OT10',
  [Periods.BEFORE_PENALTY_SHOOTOUT]: 'Break',
  [Periods.PENALTY_SHOOTOUT]: 'Pens',
  [Periods.POST_GAME]: 'Finished',
  [Periods.GAME_ABANDONED]: 'Suspended',
};

const livePeriods = [
  Periods.FIRST_PERIOD,
  Periods.BEFORE_SECOND_PERIOD,
  Periods.SECOND_PERIOD,
  Periods.BEFORE_THIRD_PERIOD,
  Periods.THIRD_PERIOD,
  Periods.BEFORE_OVERTIME,
  Periods.OVERTIME_1,
  Periods.OVERTIME_2,
  Periods.OVERTIME_3,
  Periods.OVERTIME_4,
  Periods.OVERTIME_5,
  Periods.OVERTIME_6,
  Periods.OVERTIME_7,
  Periods.OVERTIME_8,
  Periods.OVERTIME_9,
  Periods.OVERTIME_10,
  Periods.BEFORE_PENALTY_SHOOTOUT,
  Periods.PENALTY_SHOOTOUT,
];

const getLivePeriods = () => [...livePeriods];

const isPrematch = (event) => Event.isPrematch(event) || event.period === Periods.PRE_GAME;

const isLive = (event) => Event.isLive(event) && !includes([Periods.POST_GAME, Periods.GAME_ABANDONED, event.period]);

const getPeriodTime = ({ secondsLeftInPeriod }) => formatMinutesAndSeconds(secondsLeftInPeriod);

const getPeriodLabel = ({
  period,
  periodLengthInMinutes,
  overtimeLengthInMinutes,
  secondsLeftInPeriod,
}) => {
  const label = PeriodLabels[period] || '';
  if (!includes(activePeriods, period)) return label;

  // We don't want to display time for penalties
  if (period === Periods.PENALTY_SHOOTOUT) return label;

  const time = getPeriodTime({
    period,
    periodLengthInMinutes,
    overtimeLengthInMinutes,
    secondsLeftInPeriod,
  });

  return `${label} · ${time}`;
};

const getPeriodValue = (period) => {
  if (period === 'TOTAL') return 0;
  return indexOf(keys(Periods), period) + 1;
};

const getDetails = (event, prematch) => {
  const scorePerPeriod = map(
    Event.getAvailablePeriods({
      allPeriods: keys(Periods),
      scorePerPeriod: event.home.scorePerPeriod,
      mustHavePeriods,
    }),
    (period) => {
      const isVisible = !prematch && getPeriodValue(event.period) >= getPeriodValue(period);
      return {
        code: period,
        label: PeriodAbbreviations[period],
        homeValue: isVisible ? event.home.scorePerPeriod[period] || 0 : '',
        awayValue: isVisible ? event.away.scorePerPeriod[period] || 0 : '',
      };
    },
  );

  return [
    {
      code: 'TOTAL',
      label: PeriodAbbreviations.TOTAL,
      homeValue: prematch ? '-' : event.home.score,
      awayValue: prematch ? '-' : event.away.score,
    },
    ...scorePerPeriod,
  ];
};

const updateEvent = (eventDetails, payload) => {
  const updatedEventDetails = cloneDeep(eventDetails);

  assign(
    updatedEventDetails,
    {
      ...updatedEventDetails,
      period: payload.state.currentClock?.period,
      periods: payload.rules.periods,
      secondsLeftInPeriod: payload.state.currentClock?.secondsLeftInPeriod,
      periodLengthInMinutes: payload.rules.periodLengthInMinutes,
      overtimeLengthInMinutes: payload.rules.otLengthInMinutes,
      isOvertimePossible: payload.rules.otPossible,
      isClockRunning: payload.state.currentClock?.clockRunning,
      teamInPossession: payload.state.teamInPossession,
      timeOfLastClockStart: payload.state.currentClock?.timeOfLastClockStart,
      eventInBreak: payload.state.eventInBreak,
      eventPreMatch: payload.state.eventPreMatch,
      homeScore: payload.state.homeScore,
      awayScore: payload.state.awayScore,
      homeLineup: payload.competitors?.homeTeam?.lineup,
      awayLineup: payload.competitors?.awayTeam?.lineup,
      scorePerPeriod: payload.state.scorePerPeriod,
      foulsPerPeriod: payload.state.penaltiesPerPeriod,
      shotsOnGoal: payload.state.shotsOnGoal,
      shotsOffTarget: payload.state.shotsOffTarget,
      blockedShots: payload.state.blockedShots,
      goalsScoredOnEmptyNet: payload.state.goalsScoredOnEmptyNet,
      powerPlayPercentage: payload.state.powerPlayPercentage,
      totalShotsOnGoal: payload.state.totalShotsOnGoal,
      totalShotsOffTarget: payload.state.totalShotsOffTarget,
      totalBlockedShots: payload.state.totalBlockedShots,
      totalGoalsScoredOnEmptyNet: payload.state.totalGoalsScoredOnEmptyNet,
      totalPowerPlayPercentage: payload.state.totalPowerPlayPercentage,
      actions: payload.state.actions,
      riskFlags: payload.state.riskFlags,
    },
  );

  return updatedEventDetails;
};

const formatPeriodTime = ({ secondsLeftInPeriod }) => formatMinutesAndSeconds(secondsLeftInPeriod);

const formatPeriodMinute = ({ secondsLeftInPeriod }) => formatMinutesAndSeconds(secondsLeftInPeriod);

const formatPeriodLabel = ({ period }) => PeriodLabels[period] ?? period;

const formatPeriodAbbreviation = ({ period }) => PeriodAbbreviations[period] ?? period;

const formatPeriod = (details, options) => {
  const formatLabel = options?.formatLabel ?? formatPeriodLabel;
  const formatTime = options?.formatTime ?? formatPeriodMinute;
  const separator = options?.separator ?? '·';

  const label = formatLabel(details);
  const time = formatTime(details);

  return (includes(activePeriods, details.period) ? `${label} ${separator} ${time}` : label);
};

const calculatePeriodProgress = ({
  period,
  periodLengthInMinutes,
  overtimeLengthInMinutes,
  secondsLeftInPeriod,
}) => {
  if (!includes(activePeriods, period)) return -1;
  if (includes(PeriodAbbreviations[period], 'OT')) return (((overtimeLengthInMinutes * 60) - secondsLeftInPeriod) / (overtimeLengthInMinutes * 60)) * 100;
  return (((periodLengthInMinutes * 60) - secondsLeftInPeriod) / (periodLengthInMinutes * 60)) * 100;
};

const statisticTypes = {
  FOULS: 'FOULS',
  SHOTS_ON_GOAL: 'SHOTS_ON_GOAL',
  SHOTS_OFF_TARGET: 'SHOTS_OFF_TARGET',
  BLOCKED_SHOTS: 'BLOCKED_SHOTS',
  GOAL_SCORED_ON_EMPTY_NET: 'GOAL_SCORED_ON_EMPTY_NET',
  POWER_PLAY_PERCENTAGE: 'POWER_PLAY_PERCENTAGE',
};

const createStatisticsConfiguration = (event) => ({
  periods: Event.getAvailablePeriods({
    allPeriods: keys(Periods),
    scorePerPeriod: event.home.scorePerPeriod,
    mustHavePeriods,
  }),
  fields: {
    [statisticTypes.FOULS]: 'foulsPerPeriod',
    [statisticTypes.SHOTS_ON_GOAL]: 'shotsOnGoal',
    [statisticTypes.SHOTS_OFF_TARGET]: 'shotsOffTarget',
    [statisticTypes.BLOCKED_SHOTS]: 'blockedShots',
    [statisticTypes.GOAL_SCORED_ON_EMPTY_NET]: 'goalsScoredOnEmptyNet',
    [statisticTypes.POWER_PLAY_PERCENTAGE]: 'powerPlayPercentage',
  },
  totalFields: {
    [statisticTypes.SHOTS_ON_GOAL]: 'totalShotsOnGoal',
    [statisticTypes.SHOTS_OFF_TARGET]: 'totalShotsOffTarget',
    [statisticTypes.BLOCKED_SHOTS]: 'totalBlockedShots',
    [statisticTypes.GOAL_SCORED_ON_EMPTY_NET]: 'totalGoalsScoredOnEmptyNet',
    [statisticTypes.POWER_PLAY_PERCENTAGE]: 'totalPowerPlayPercentage',
  },
  labels: {
    [statisticTypes.FOULS]: 'Fouls',
    [statisticTypes.SHOTS_ON_GOAL]: 'Shots on goal',
    [statisticTypes.SHOTS_OFF_TARGET]: 'Shots off target',
    [statisticTypes.BLOCKED_SHOTS]: 'Blocked shots',
    [statisticTypes.GOAL_SCORED_ON_EMPTY_NET]: 'Goals scored on empty net',
    [statisticTypes.POWER_PLAY_PERCENTAGE]: 'Power play %',
  },
  formatters: {
    [statisticTypes.POWER_PLAY_PERCENTAGE]: (value) => (isFinite(value) ? (value * 100).toFixed(1) : '-'),
  },
  totalFormatters: {},
  transform({ period, value }) {
    if (period === 'TOTAL') return value ?? NaN;
    return value?.[period] ?? NaN;
  },
});

const getAvailablePeriods = (event) => [
  {
    id: 'TOTAL',
    label: 'Full event',
  },
  ...map(
    Event.getAvailablePeriods({
      allPeriods: keys(Periods),
      scorePerPeriod: event.home.scorePerPeriod,
      mustHavePeriods,
    }),
    (period) => ({
      id: period,
      label: PeriodLabels[period],
    }),
  ),
];

export default {
  ID,
  Periods,
  mustHavePeriods,
  activePeriods,
  PeriodAbbreviations,
  PeriodLabels,
  isPrematch,
  isLive,
  getPeriodTime,
  getPeriodLabel,
  getPeriodValue,
  getDetails,
  updateEvent,
  formatPeriodTime,
  formatPeriodMinute,
  formatPeriodLabel,
  formatPeriodAbbreviation,
  formatPeriod,
  calculatePeriodProgress,
  createStatisticsConfiguration,
  getAvailablePeriods,
  getLivePeriods,
};
