import {
  includes,
  keys,
  union,
  intersection,
  reduce,
  find,
  orderBy,
  map,
  slice,
} from 'lodash';
import formatDate from '@/utils/format-date';

const MatchState = {
  N_A: 'N_A',
  PREMATCH: 'PREMATCH',
  LIVE: 'LIVE',
  FINISHED: 'FINISHED',
  CANCELED: 'CANCELED',
};

const parseDateTime = ({ startsAt }) => formatDate(startsAt);

const getAvailablePeriods = ({ allPeriods, scorePerPeriod, mustHavePeriods = [] }) => union(intersection(allPeriods, keys(scorePerPeriod)), mustHavePeriods);

const isPrematch = ({ matchState, period }) => !matchState || includes([MatchState.N_A, MatchState.PREMATCH], matchState) || !period;

const isLive = ({ matchState, period }) => !isPrematch({ matchState, period }) && matchState === MatchState.LIVE;

const isFinished = ({ matchState }) => matchState && matchState === MatchState.FINISHED;

const isCanceled = ({ matchState }) => matchState && matchState === MatchState.CANCELED;

/**
 * Returns event details array based on event's sport ID.
 *
 * @param {Object} event Event from which data is extracted
 * @return Event details array or falsy value
 */
const getAllEventDetails = (event) => {
  switch (event.sportId) {
  case '09eb8090-68f3-4a53-838f-f79e7a6912c3':
    return event.soccerEventDetails?.nodes ?? [];
  case 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f':
    return event.basketballEventDetails?.nodes ?? [];
  case '841e188b-0dcf-4f5f-974f-aa52c8cec95b':
    return event.footballEventDetails?.nodes ?? [];
  case '6093d1bf-d572-486b-af56-96287ee0e865':
    return event.ultimateEventDetails?.nodes ?? [];
  case 'db5e8b75-30a3-4a97-9112-f28b8e962887':
    return event.hockeyEventDetails?.nodes ?? [];
  case '4e8eca10-6afa-44ed-af77-42414ec45cb3':
    return event.baseballEventDetails?.nodes ?? [];
  default:
    return null;
  }
};

const findEventAggregateByFeedPriority = ({ aggregates, priorities }) => {
  if (!priorities?.length) return null;
  const foundEventAggregate = find(aggregates, { source: priorities[0] });
  if (!foundEventAggregate) return findEventAggregateByFeedPriority({ aggregates, priorities: slice(priorities, 1) });
  return foundEventAggregate;
};

const findDefaultEventAggregateByFeedPriority = ({ aggregates, priorities }) => {
  const foundAggregate = findEventAggregateByFeedPriority({ aggregates, priorities });
  if (foundAggregate) return foundAggregate;
  return aggregates?.[0] || null;
};

const findEventAggregate = (event, selectedFeed) => {
  const aggregates = getAllEventDetails(event);

  if (selectedFeed) {
    return findEventAggregateByFeedPriority({
      aggregates,
      priorities: [selectedFeed],
    });
  }

  const foundOverridenAggregate = findEventAggregateByFeedPriority({
    aggregates,
    priorities: event.primaryFeedOverrides?.nodes?.[0]?.primaryFeeds || [],
  });

  if (foundOverridenAggregate) return foundOverridenAggregate;

  return findDefaultEventAggregateByFeedPriority({
    aggregates,
    priorities: map(orderBy([...event.competition?.competitionFeedConfiguration?.nodes || []], ['priority'], ['asc']), 'feed'),
  });
};

const getEventDetailsSource = (event) => findEventAggregate(event)?.source || '';

const findEventDetails = (event, selectedFeed) => findEventAggregate(event, selectedFeed) || null;

const mapEventIdAndSource = (event, selectedFeed) => {
  const source = selectedFeed || getEventDetailsSource(event);
  return { id: event.eventId, source };
};

const mapAllEventIdsAndSource = (allEvents) => map(allEvents, (event) => mapEventIdAndSource(event));

const groupEventIdsBySource = (events) => reduce(
  mapAllEventIdsAndSource(events),
  (result, event) => ({
    ...result,
    [event.source]: [
      ...(result[event.source] || []),
      event.id,
    ],
  }),
  {},
);
export default {
  MatchState,
  parseDateTime,
  getAvailablePeriods,
  isPrematch,
  isLive,
  isFinished,
  isCanceled,
  getAllEventDetails,
  getEventDetailsSource,
  findEventDetails,
  mapEventIdAndSource,
  mapAllEventIdsAndSource,
  groupEventIdsBySource,
};
