import {
  assign,
  cloneDeep,
  map,
  keys,
} from 'lodash';
import Event from './event';

const Periods = {
  INNING_1: 'INNING_1',
  INNING_2: 'INNING_2',
  INNING_3: 'INNING_3',
  INNING_4: 'INNING_4',
  INNING_5: 'INNING_5',
  INNING_6: 'INNING_6',
  INNING_7: 'INNING_7',
  INNING_8: 'INNING_8',
  INNING_9: 'INNING_9',
  INNING_10: 'INNING_10',
  INNING_11: 'INNING_11',
  INNING_12: 'INNING_12',
  INNING_13: 'INNING_13',
  INNING_14: 'INNING_14',
};

const mustHavePeriods = [
  Periods.INNING_1,
  Periods.INNING_2,
  Periods.INNING_3,
  Periods.INNING_4,
  Periods.INNING_5,
  Periods.INNING_6,
  Periods.INNING_7,
  Periods.INNING_8,
  Periods.INNING_9,
];

const livePeriods = [
  Periods.INNING_1,
  Periods.INNING_2,
  Periods.INNING_3,
  Periods.INNING_4,
  Periods.INNING_5,
  Periods.INNING_6,
  Periods.INNING_7,
  Periods.INNING_8,
  Periods.INNING_9,
  Periods.INNING_10,
  Periods.INNING_11,
  Periods.INNING_12,
  Periods.INNING_13,
  Periods.INNING_14,
];

const PeriodLabels = {
  TOTAL: 'Match',
  [Periods.INNING_1]: '1st Inning',
  [Periods.INNING_2]: '2nd Inning',
  [Periods.INNING_3]: '3rd Inning',
  [Periods.INNING_4]: '4th Inning',
  [Periods.INNING_5]: '5th Inning',
  [Periods.INNING_6]: '6th Inning',
  [Periods.INNING_7]: '7th Inning',
  [Periods.INNING_8]: '8th Inning',
  [Periods.INNING_9]: '9th Inning',
  [Periods.INNING_10]: '10th Inning',
  [Periods.INNING_11]: '11th Inning',
  [Periods.INNING_12]: '12th Inning',
  [Periods.INNING_13]: '13th Inning',
  [Periods.INNING_14]: '14th Inning',
};

const PeriodAbbreviations = {
  TOTAL: 'Match',
  [Periods.INNING_1]: '1st Inn',
  [Periods.INNING_2]: '2nd Inn',
  [Periods.INNING_3]: '3rd Inn',
  [Periods.INNING_4]: '4th Inn',
  [Periods.INNING_5]: '5th Inn',
  [Periods.INNING_6]: '6th Inn',
  [Periods.INNING_7]: '7th Inn',
  [Periods.INNING_8]: '8th Inn',
  [Periods.INNING_9]: '9th Inn',
  [Periods.INNING_10]: '10th Inn',
  [Periods.INNING_11]: '11th Inn',
  [Periods.INNING_12]: '12th Inn',
  [Periods.INNING_13]: '13th Inn',
  [Periods.INNING_14]: '14th Inn',
};

const getLivePeriods = () => [...livePeriods];

const getPeriodLabel = ({ period, periodStatus, isClockRunning }) => {
  if (!isClockRunning) return 'Break';
  const prefix = periodStatus === 'TOP' ? '▲' : '▼';
  const label = PeriodLabels[period] || period;
  return `${prefix} ${label}`;
};

const getPeriodAbbreviation = ({ period, periodStatus, isClockRunning }) => {
  if (!isClockRunning) return 'Break';
  const prefix = periodStatus === 'TOP' ? '▲' : '▼';
  const label = PeriodAbbreviations[period] || period;
  return `${prefix} ${label}`;
};

const getDetails = (event, prematch) => {
  const scorePerPeriod = map(
    event.periods,
    (period) => ({
      code: period,
      label: PeriodAbbreviations[period] || period,
      homeValue: event.home.scorePerPeriod[period] || 0,
      awayValue: event.away.scorePerPeriod[period] || 0,
    }),
  );

  return [
    {
      code: 'TOTAL',
      label: 'Match',
      homeValue: prematch ? '-' : event.home.runs,
      awayValue: prematch ? '-' : event.away.runs,
    },
    ...scorePerPeriod,
  ];
};

const updateEvent = (eventDetails, payload) => {
  const updatedEventDetails = cloneDeep(eventDetails);

  assign(
    updatedEventDetails,
    {
      ...updatedEventDetails,
      currentTeamABatterProviderId: payload.state.currentTeamABatterProviderId,
      currentTeamBPitcherProviderId: payload.state.currentTeamBPitcherProviderId,
      currentTeamAPitcherId: payload.state.currentTeamAPitcherId,
      currentTeamBPitcherId: payload.state.currentTeamBPitcherId,
      currentTeamABatterId: payload.state.currentTeamABatterId,
      currentTeamBBatterId: payload.state.currentTeamBBatterId,
      inningDetails: payload.state.inningDetails,
      lastUpdated: payload.state.lastUpdated,
      matchHits: payload.state.matchHits,
      matchHomeRuns: payload.state.matchHomeRuns,
      matchRuns: payload.state.matchRuns,
      raceToMap: payload.state.raceToMap,
      homeLineup: payload.competitors?.homeTeam?.lineup,
      awayLineup: payload.competitors?.awayTeam?.lineup,
      eventInBreak: payload.state.eventInBreak,
      eventPreMatch: payload.state.eventPreMatch,
      pitchCount: payload.state.pitchCount,
      loadedBases: payload.state.loadedBases,
      strikeOuts: payload.state.strikeOuts,
      riskFlags: payload.state.riskFlags,
    },
  );

  return updatedEventDetails;
};

const isPrematch = (event) => Event.isPrematch(event);

const isLive = (event) => Event.isLive(event);

const formatPeriodTime = () => -1;

const formatPeriodMinute = () => -1;

const formatPeriodLabel = (details) => getPeriodLabel(details) ?? details.period;

const formatPeriodAbbreviation = (details) => getPeriodAbbreviation(details) ?? details.period;

const formatPeriod = (details, options) => {
  const formatLabel = options?.formatLabel ?? formatPeriodLabel;
  const label = formatLabel(details);
  return label;
};

const calculatePeriodProgress = () => -1;

const statisticTypes = {
  RUNS: 'RUNS',
  HITS: 'HITS',
  HOME_RUNS: 'HOME_RUNS',
  STRIKEOUTS: 'STRIKEOUTS',
};

const createStatisticsConfiguration = (event) => ({
  periods: Event.getAvailablePeriods({
    allPeriods: keys(Periods),
    scorePerPeriod: event.home.scorePerPeriod,
    mustHavePeriods,
  }),
  fields: {
    [statisticTypes.RUNS]: 'scorePerPeriod',
    [statisticTypes.HITS]: 'hitsPerPeriod',
    [statisticTypes.HOME_RUNS]: 'homeRunsPerPeriod',
    [statisticTypes.STRIKEOUTS]: 'strikeOutsPerPeriod',
  },
  totalFields: {
    [statisticTypes.RUNS]: 'score',
    [statisticTypes.HITS]: 'hits',
    [statisticTypes.HOME_RUNS]: 'homeRuns',
    [statisticTypes.STRIKEOUTS]: 'strikeOuts',
  },
  labels: {
    [statisticTypes.RUNS]: 'Runs',
    [statisticTypes.HITS]: 'Hits',
    [statisticTypes.HOME_RUNS]: 'Home runs',
    [statisticTypes.STRIKEOUTS]: 'Strikeouts',
  },
  formatters: {},
  totalFormatters: {},
  transform({ period, value }) {
    if (period === 'TOTAL') return value ?? NaN;
    return value?.[period] ?? NaN;
  },
});

const getAvailablePeriods = (event) => [
  {
    id: 'TOTAL',
    label: 'Match',
  },
  ...map(
    event.periods,
    (period) => ({
      id: period,
      label: PeriodLabels[period],
    }),
  ),
];

const createPitcherPlayerStatisticsColumns = () => ({
  hits: {
    id: 'hits',
    label: 'H',
    tooltip: 'Hits',
    field: 'stats.pitcherDetails.hits',
    style: {
      'text-align': 'right',
    },
  },
  runs: {
    id: 'runs',
    label: 'R',
    tooltip: 'Runs',
    field: 'stats.pitcherDetails.runs',
    style: {
      'text-align': 'right',
    },
  },
  homeRuns: {
    id: 'homeRuns',
    label: 'HR',
    tooltip: 'Home Runs',
    field: 'stats.pitcherDetails.homeRuns',
    style: {
      'text-align': 'right',
    },
  },
  walks: {
    id: 'walks',
    label: 'BB',
    tooltip: 'Walks',
    field: 'stats.pitcherDetails.walks',
    style: {
      'text-align': 'right',
    },
  },
  strikeOuts: {
    id: 'strikeOuts',
    label: 'SO',
    tooltip: 'Strikeouts',
    field: 'stats.pitcherDetails.strikeOuts',
    style: {
      'text-align': 'right',
    },
  },
  battersFaced: {
    id: 'battersFaced',
    label: 'BF',
    tooltip: 'Batters Faced',
    field: 'stats.pitcherDetails.battersFaced',
    style: {
      'text-align': 'right',
    },
  },
  stolenBases: {
    id: 'stolenBases',
    label: 'SB',
    tooltip: 'Stolen Bases',
    field: 'stats.pitcherDetails.stolenBases',
    style: {
      'text-align': 'right',
    },
  },
});

const createBatterPlayerStatisticsColumns = () => ({
  atBats: {
    id: 'atBats',
    label: 'AB',
    tooltip: 'At Bats',
    field: 'stats.batterDetails.atBats',
    style: {
      'text-align': 'right',
    },
  },
  runs: {
    id: 'runs',
    label: 'R',
    tooltip: 'Runs',
    field: 'stats.batterDetails.runs',
    style: {
      'text-align': 'right',
    },
  },
  hits: {
    id: 'hits',
    label: 'H',
    tooltip: 'Hits',
    field: 'stats.batterDetails.hits',
    style: {
      'text-align': 'right',
    },
  },
  singles: {
    id: 'singles',
    label: '1B',
    tooltip: 'Singles',
    field: 'stats.batterDetails.singles',
    style: {
      'text-align': 'right',
    },
  },
  doubles: {
    id: 'doubles',
    label: '2B',
    tooltip: 'Doubles',
    field: 'stats.batterDetails.doubles',
    style: {
      'text-align': 'right',
    },
  },
  triples: {
    id: 'triples',
    label: '3B',
    tooltip: 'Triples',
    field: 'stats.batterDetails.triples',
    style: {
      'text-align': 'right',
    },
  },
  homeRuns: {
    id: 'homeRuns',
    label: 'HR',
    tooltip: 'Home Runs',
    field: 'stats.batterDetails.homeRuns',
    style: {
      'text-align': 'right',
    },
  },
  rbis: {
    id: 'rbis',
    label: 'RBI',
    tooltip: 'Runs Batted In',
    field: 'stats.batterDetails.rbis',
    style: {
      'text-align': 'right',
    },
  },
  walks: {
    id: 'walks',
    label: 'BB',
    tooltip: 'Walks',
    field: 'stats.batterDetails.walks',
    style: {
      'text-align': 'right',
    },
  },
  strikeOuts: {
    id: 'strikeOuts',
    label: 'SO',
    tooltip: 'Walks',
    field: 'stats.batterDetails.strikeOuts',
    style: {
      'text-align': 'right',
    },
  },
  hitByPitch: {
    id: 'hitByPitch',
    label: 'HBP',
    tooltip: 'Hit-by-Pitch',
    field: 'stats.batterDetails.hitByPitch',
    style: {
      'text-align': 'right',
    },
  },
  bases: {
    id: 'bases',
    label: 'TB',
    tooltip: 'Total Bases',
    field: 'stats.batterDetails.bases',
    style: {
      'text-align': 'right',
    },
  },
});

export const createPlayerStatisticsColumns = (options) => {
  switch (options?.type) {
  case 'pitchers':
    return createPitcherPlayerStatisticsColumns();
  case 'hitters':
    return createBatterPlayerStatisticsColumns();
  default:
    return {};
  }
};

export default {
  getLivePeriods,
  getPeriodLabel,
  getDetails,
  updateEvent,
  isPrematch,
  isLive,
  formatPeriodTime,
  formatPeriodMinute,
  formatPeriodLabel,
  formatPeriodAbbreviation,
  formatPeriod,
  calculatePeriodProgress,
  createStatisticsConfiguration,
  getAvailablePeriods,
  createPlayerStatisticsColumns,
};
