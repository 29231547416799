import {
  map,
  indexOf,
  cloneDeep,
  assign,
  keys,
  includes,
  orderBy,
  values,
  isFinite,
} from 'lodash';
import { formatMinutesAndSeconds } from '@/utils/number';
import Event from './event';

export const ID = 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f';

const Periods = {
  PRE_GAME: 'PRE_GAME',
  FIRST_PERIOD: 'FIRST_PERIOD',
  BEFORE_SECOND_PERIOD: 'BEFORE_SECOND_PERIOD',
  SECOND_PERIOD: 'SECOND_PERIOD',
  FIRST_HALF: 'FIRST_HALF',
  HALF_TIME: 'HALF_TIME',
  THIRD_PERIOD: 'THIRD_PERIOD',
  BEFORE_FOURTH_PERIOD: 'BEFORE_FOURTH_PERIOD',
  FOURTH_PERIOD: 'FOURTH_PERIOD',
  SECOND_HALF: 'SECOND_HALF',
  BEFORE_OVERTIME: 'BEFORE_OVERTIME',
  OVERTIME_1: 'OVERTIME_1',
  OVERTIME_2: 'OVERTIME_2',
  OVERTIME_3: 'OVERTIME_3',
  OVERTIME_4: 'OVERTIME_4',
  OVERTIME_5: 'OVERTIME_5',
  OVERTIME_6: 'OVERTIME_6',
  OVERTIME_7: 'OVERTIME_7',
  OVERTIME_8: 'OVERTIME_8',
  OVERTIME_9: 'OVERTIME_9',
  OVERTIME_10: 'OVERTIME_10',
  POST_GAME: 'POST_GAME',
  GAME_ABANDONED: 'GAME_ABANDONED',
};

const mustHavePeriods = [
  Periods.FIRST_PERIOD,
  Periods.SECOND_PERIOD,
  Periods.THIRD_PERIOD,
  Periods.FOURTH_PERIOD,
];

const mustHavePeriodsNcaa = [
  Periods.FIRST_PERIOD,
  Periods.SECOND_PERIOD,
];

const activePeriods = [
  Periods.FIRST_PERIOD,
  Periods.SECOND_PERIOD,
  Periods.THIRD_PERIOD,
  Periods.FOURTH_PERIOD,
  Periods.OVERTIME_1,
  Periods.OVERTIME_2,
  Periods.OVERTIME_3,
  Periods.OVERTIME_4,
  Periods.OVERTIME_5,
  Periods.OVERTIME_6,
  Periods.OVERTIME_7,
  Periods.OVERTIME_8,
  Periods.OVERTIME_9,
  Periods.OVERTIME_10,
];

const livePeriods = [
  Periods.FIRST_PERIOD,
  Periods.BEFORE_SECOND_PERIOD,
  Periods.SECOND_PERIOD,
  Periods.HALF_TIME,
  Periods.THIRD_PERIOD,
  Periods.BEFORE_FOURTH_PERIOD,
  Periods.FOURTH_PERIOD,
  Periods.BEFORE_OVERTIME,
  Periods.OVERTIME_1,
  Periods.OVERTIME_2,
  Periods.OVERTIME_3,
  Periods.OVERTIME_4,
  Periods.OVERTIME_5,
  Periods.OVERTIME_6,
  Periods.OVERTIME_7,
  Periods.OVERTIME_8,
  Periods.OVERTIME_9,
  Periods.OVERTIME_10,
];

const ncaaLivePeriods = [
  Periods.FIRST_HALF,
  Periods.HALF_TIME,
  Periods.SECOND_HALF,
  Periods.BEFORE_OVERTIME,
  Periods.OVERTIME_1,
  Periods.OVERTIME_2,
  Periods.OVERTIME_3,
  Periods.OVERTIME_4,
  Periods.OVERTIME_5,
  Periods.OVERTIME_6,
  Periods.OVERTIME_7,
  Periods.OVERTIME_8,
  Periods.OVERTIME_9,
  Periods.OVERTIME_10,
];

const getLivePeriods = ({ competitionType }) => (competitionType === 'NCAA' ? ncaaLivePeriods : livePeriods);

const PeriodLabels = {
  TOTAL: 'Match',
  N_A: 'N/A',
  [Periods.PRE_GAME]: 'Pregame',
  [Periods.FIRST_PERIOD]: 'Q1',
  [Periods.BEFORE_SECOND_PERIOD]: 'Break',
  [Periods.SECOND_PERIOD]: 'Q2',
  [Periods.HALF_TIME]: 'Half time',
  [Periods.THIRD_PERIOD]: 'Q3',
  [Periods.BEFORE_FOURTH_PERIOD]: 'Break',
  [Periods.FOURTH_PERIOD]: 'Q4',
  [Periods.BEFORE_OVERTIME]: 'Break',
  [Periods.OVERTIME_1]: 'OT',
  [Periods.OVERTIME_2]: 'OT2',
  [Periods.OVERTIME_3]: 'OT3',
  [Periods.OVERTIME_4]: 'OT4',
  [Periods.OVERTIME_5]: 'OT5',
  [Periods.OVERTIME_6]: 'OT6',
  [Periods.OVERTIME_7]: 'OT7',
  [Periods.OVERTIME_8]: 'OT8',
  [Periods.OVERTIME_9]: 'OT9',
  [Periods.OVERTIME_10]: 'OT10',
  [Periods.POST_GAME]: 'Finished',
  [Periods.GAME_ABANDONED]: 'Suspended',
};

const NcaaPeriodLabels = {
  TOTAL: 'Match',
  N_A: 'N/A',
  [Periods.PRE_GAME]: 'Pregame',
  [Periods.FIRST_PERIOD]: 'H1',
  [Periods.BEFORE_SECOND_PERIOD]: 'Half time',
  [Periods.HALF_TIME]: 'Half time',
  [Periods.SECOND_PERIOD]: 'H2',
  [Periods.BEFORE_OVERTIME]: 'Break',
  [Periods.OVERTIME_1]: 'OT',
  [Periods.OVERTIME_2]: 'OT2',
  [Periods.OVERTIME_3]: 'OT3',
  [Periods.OVERTIME_4]: 'OT4',
  [Periods.OVERTIME_5]: 'OT5',
  [Periods.OVERTIME_6]: 'OT6',
  [Periods.OVERTIME_7]: 'OT7',
  [Periods.OVERTIME_8]: 'OT8',
  [Periods.OVERTIME_9]: 'OT9',
  [Periods.OVERTIME_10]: 'OT10',
  [Periods.POST_GAME]: 'Finished',
  [Periods.GAME_ABANDONED]: 'Suspended',
};

const PeriodAbbreviations = {
  TOTAL: 'Match',
  N_A: 'N/A',
  [Periods.PRE_GAME]: 'Pregame',
  [Periods.FIRST_PERIOD]: 'Q1',
  [Periods.BEFORE_SECOND_PERIOD]: 'Break',
  [Periods.SECOND_PERIOD]: 'Q2',
  [Periods.HALF_TIME]: 'HT',
  [Periods.THIRD_PERIOD]: 'Q3',
  [Periods.BEFORE_FOURTH_PERIOD]: 'Break',
  [Periods.FOURTH_PERIOD]: 'Q4',
  [Periods.BEFORE_OVERTIME]: 'Break',
  [Periods.OVERTIME_1]: 'OT',
  [Periods.OVERTIME_2]: 'OT2',
  [Periods.OVERTIME_3]: 'OT3',
  [Periods.OVERTIME_4]: 'OT4',
  [Periods.OVERTIME_5]: 'OT5',
  [Periods.OVERTIME_6]: 'OT6',
  [Periods.OVERTIME_7]: 'OT7',
  [Periods.OVERTIME_8]: 'OT8',
  [Periods.OVERTIME_9]: 'OT9',
  [Periods.OVERTIME_10]: 'OT10',
  [Periods.POST_GAME]: 'Finished',
  [Periods.GAME_ABANDONED]: 'Suspended',
};

const NcaaPeriodAbbreviations = {
  TOTAL: 'Match',
  N_A: 'N/A',
  [Periods.PRE_GAME]: 'Pregame',
  [Periods.FIRST_PERIOD]: 'H1',
  [Periods.BEFORE_SECOND_PERIOD]: 'HT',
  [Periods.HALF_TIME]: 'HT',
  [Periods.SECOND_PERIOD]: 'H2',
  [Periods.BEFORE_OVERTIME]: 'Break',
  [Periods.OVERTIME_1]: 'OT',
  [Periods.OVERTIME_2]: 'OT2',
  [Periods.OVERTIME_3]: 'OT3',
  [Periods.OVERTIME_4]: 'OT4',
  [Periods.OVERTIME_5]: 'OT5',
  [Periods.OVERTIME_6]: 'OT6',
  [Periods.OVERTIME_7]: 'OT7',
  [Periods.OVERTIME_8]: 'OT8',
  [Periods.OVERTIME_9]: 'OT9',
  [Periods.OVERTIME_10]: 'OT10',
  [Periods.POST_GAME]: 'Finished',
  [Periods.GAME_ABANDONED]: 'Suspended',
};

const isPrematch = (event) => Event.isPrematch(event) || event.period === Periods.PRE_GAME;

const isLive = (event) => Event.isLive(event) && !includes([Periods.POST_GAME, Periods.GAME_ABANDONED, event.period]);

const getPeriodTime = ({ secondsLeftInPeriod }) => formatMinutesAndSeconds(secondsLeftInPeriod);

const getPeriodLabel = ({
  competitionType,
  period,
  periodLengthInMinutes,
  overtimeLengthInMinutes,
  secondsLeftInPeriod,
}) => {
  const label = (competitionType === 'NCAA' ? NcaaPeriodLabels[period] : PeriodLabels[period]) ?? '';

  if (!includes(activePeriods, period)) return label;

  const time = getPeriodTime({
    period,
    periodLengthInMinutes,
    overtimeLengthInMinutes,
    secondsLeftInPeriod,
  });

  return `${label} · ${time}`;
};

const getPeriodValue = (period) => {
  if (period === 'TOTAL') return 0;
  return indexOf(keys(Periods), period) + 1;
};

const getDetails = (event, prematch) => {
  const scorePerPeriod = map(
    Event.getAvailablePeriods({
      allPeriods: keys(Periods),
      scorePerPeriod: event.home.scorePerPeriod,
      mustHavePeriods: (event.competitionType === 'NCAA' ? mustHavePeriodsNcaa : mustHavePeriods),
    }),
    (period) => {
      const isVisible = !prematch && getPeriodValue(event.period) >= getPeriodValue(period);
      return {
        code: period,
        label: (event.competitionType === 'NCAA' ? NcaaPeriodAbbreviations[period] : PeriodAbbreviations[period]),
        homeValue: isVisible ? event.home.scorePerPeriod[period] || 0 : '',
        awayValue: isVisible ? event.away.scorePerPeriod[period] || 0 : '',
      };
    },
  );

  return [
    {
      code: 'TOTAL',
      label: PeriodAbbreviations.TOTAL,
      homeValue: prematch ? '-' : event.home.score,
      awayValue: prematch ? '-' : event.away.score,
    },
    ...scorePerPeriod,
  ];
};

const updateEvent = (eventDetails, payload) => {
  const updatedEventDetails = cloneDeep(eventDetails);

  assign(
    updatedEventDetails,
    {
      ...updatedEventDetails,
      lastTeamToScore: payload.state.lastTeamToScore,
      ftsToCome: payload.state.ftsToCome,
      teamInPossession: payload.state.teamInPossession,
      period: payload.state.currentClock?.period,
      secondsLeftInPeriod: payload.state.currentClock?.secondsLeftInPeriod,
      points: payload.state.points,
      scorePerPeriod: payload.state.scorePerPeriod,
      threePointersPerPeriod: payload.state.threePointersPerPeriod,
      twoPointersPerPeriod: payload.state.twoPointersPerPeriod,
      onePointersPerPeriod: payload.state.onePointersPerPeriod,
      foulsPerPeriod: payload.state.foulsPerPeriod,
      fieldGoalPerPeriod: payload.state.fieldGoalPerPeriod,
      fieldGoalPercentagePerPeriod: payload.state.fieldGoalPercentagePerPeriod,
      fieldGoalPercentageInMatch: payload.state.fieldGoalPercentageInMatch,
      twoPointAttemptsPerPeriod: payload.state.twoPointAttemptsPerPeriod,
      threePointAttemptsPerPeriod: payload.state.threePointAttemptsPerPeriod,
      reboundsPerPeriod: payload.state.reboundsPerPeriod,
      assistsPerPeriod: payload.state.assistsPerPeriod,
      possessionsCountPerPeriod: payload.state.possessionsCountPerPeriod,
      homeScore: payload.state.homeScore,
      awayScore: payload.state.awayScore,
      homeLineup: payload.competitors?.homeTeam?.lineup,
      awayLineup: payload.competitors?.awayTeam?.lineup,
      eventInBreak: payload.state.eventInBreak,
      eventPreMatch: payload.state.eventPreMatch,
      actions: payload.state.actions,
      riskFlags: payload.state.riskFlags,
    },
  );

  return updatedEventDetails;
};

const formatPeriodTime = ({ secondsLeftInPeriod }) => formatMinutesAndSeconds(secondsLeftInPeriod);

const formatPeriodMinute = ({ secondsLeftInPeriod }) => formatMinutesAndSeconds(secondsLeftInPeriod);

const formatPeriodLabel = ({ competitionType, period }) => (competitionType === 'NCAA' ? NcaaPeriodLabels[period] : PeriodLabels[period]) ?? period;

const formatPeriodAbbreviation = ({ competitionType, period }) => (competitionType === 'NCAA' ? NcaaPeriodAbbreviations[period] : PeriodAbbreviations[period]) ?? period;

const formatPeriod = (details, options) => {
  const formatLabel = options?.formatLabel ?? formatPeriodLabel;
  const formatTime = options?.formatTime ?? formatPeriodMinute;
  const separator = options?.separator ?? '·';

  const label = formatLabel(details);
  const time = formatTime(details);

  return (includes(activePeriods, details.period) ? `${label} ${separator} ${time}` : label);
};

const calculatePeriodProgress = ({
  period,
  periodLengthInMinutes,
  overtimeLengthInMinutes,
  secondsLeftInPeriod,
}) => {
  if (!includes(activePeriods, period)) return -1;
  if (includes(PeriodAbbreviations[period], 'OT')) return (((overtimeLengthInMinutes * 60) - secondsLeftInPeriod) / (overtimeLengthInMinutes * 60)) * 100;
  return (((periodLengthInMinutes * 60) - secondsLeftInPeriod) / (periodLengthInMinutes * 60)) * 100;
};

const statisticTypes = {
  TWO_POINTERS: 'TWO_POINTERS',
  THREE_POINTERS: 'THREE_POINTERS',
  FIELD_GOALS: 'FIELD_GOALS',
  FIELD_GOALS_PERCENTAGE: 'FIELD_GOAL_PERCENTAGE',
  REBOUNDS: 'REBOUNDS',
  ASSISTS: 'ASSISTS',
  FREE_THROWS: 'FREE_THROWS',
  FOULS: 'FOULS',
  POSSESSIONS: 'POSSESSIONS',
};

const createStatisticsConfiguration = (event) => ({
  periods: Event.getAvailablePeriods({
    allPeriods: keys(Periods),
    scorePerPeriod: event.home.scorePerPeriod,
    mustHavePeriods: (event.competitionType === 'NCAA' ? mustHavePeriodsNcaa : mustHavePeriods),
  }),
  fields: {
    [statisticTypes.TWO_POINTERS]: 'twoPointersPerPeriod',
    [statisticTypes.THREE_POINTERS]: 'threePointersPerPeriod',
    [statisticTypes.FIELD_GOALS]: 'fieldGoalPerPeriod',
    [statisticTypes.FIELD_GOALS_PERCENTAGE]: 'fieldGoalPercentagePerPeriod',
    [statisticTypes.REBOUNDS]: 'reboundsPerPeriod',
    [statisticTypes.ASSISTS]: 'assistsPerPeriod',
    [statisticTypes.FREE_THROWS]: 'onePointersPerPeriod',
    [statisticTypes.FOULS]: 'foulsPerPeriod',
    [statisticTypes.POSSESSIONS]: 'possessionsCountPerPeriod',
  },
  totalFields: {
    [statisticTypes.FIELD_GOALS_PERCENTAGE]: 'fieldGoalPercentageInMatch',
  },
  labels: {
    [statisticTypes.TWO_POINTERS]: 'Two pointers',
    [statisticTypes.THREE_POINTERS]: 'Three pointers',
    [statisticTypes.FIELD_GOALS]: 'Field goals',
    [statisticTypes.FIELD_GOALS_PERCENTAGE]: 'Field goals %',
    [statisticTypes.REBOUNDS]: 'Rebounds',
    [statisticTypes.ASSISTS]: 'Assists',
    [statisticTypes.FREE_THROWS]: 'Free throws',
    [statisticTypes.FOULS]: 'Fouls',
    [statisticTypes.POSSESSIONS]: 'Possessions',
  },
  formatters: {
    [statisticTypes.FIELD_GOALS_PERCENTAGE]: (value) => (isFinite(value) ? (value * 100).toFixed(1) : '-'),
  },
  totalFormatters: {
    [statisticTypes.FIELD_GOALS_PERCENTAGE]: ({ homeValue, awayValue } = {}) => {
      const average = (homeValue + awayValue) / 2;
      return isFinite(average) ? (average * 100).toFixed(1) : '-';
    },
  },
  transform({ period, value }) {
    if (period === 'TOTAL') return value ?? NaN;
    return value?.[period] ?? NaN;
  },
});

const getAvailablePeriods = (event) => [
  {
    id: 'TOTAL',
    label: 'Full event',
  },
  ...map(
    Event.getAvailablePeriods({
      allPeriods: keys(Periods),
      scorePerPeriod: event.home.scorePerPeriod,
      mustHavePeriods: (event.competitionType === 'NCAA' ? mustHavePeriodsNcaa : mustHavePeriods),
    }),
    (period) => ({
      id: period,
      label: (event.competitionType === 'NCAA' ? NcaaPeriodLabels[period] : PeriodLabels[period]),
    }),
  ),
];

const getPeriodIndex = (periodCode) => {
  if (periodCode === 'TOTAL') return 0;
  return indexOf(values(Periods), periodCode) + 1;
};

const sortPeriods = (periods) => orderBy(periods, (period) => getPeriodIndex(period));

export const createPlayerStatisticsColumns = () => ({
  minutesPlayed: {
    id: 'minutesPlayed',
    label: 'MIN',
    tooltip: 'Total time played in minutes',
    field: 'stats.minutesPlayed',
    style: {
      'text-align': 'right',
    },
  },
  totalPoints: {
    label: 'TP',
    tooltip: 'Total points',
    field: 'stats.totalPoints',
    style: {
      'text-align': 'right',
    },
  },
  twoPointers: {
    label: '2P',
    tooltip: 'Two pointers',
    field: 'stats.totalTwoPointers',
    style: {
      'text-align': 'right',
    },
  },
  threePointers: {
    label: '3P',
    tooltip: 'Three pointers',
    field: 'stats.totalThreePointers',
    style: {
      'text-align': 'right',
    },
  },
  rebounds: {
    label: 'R',
    tooltip: 'Rebounds',
    field: 'stats.totalRebounds',
    style: {
      'text-align': 'right',
    },
  },
  assists: {
    label: 'A',
    tooltip: 'Assists',
    field: 'stats.totalAssists',
    style: {
      'text-align': 'right',
    },
  },
  fouls: {
    label: 'F',
    tooltip: 'Fouls',
    field: 'stats.totalFouls',
    style: {
      'text-align': 'right',
    },
  },
});

export default {
  ID,
  Periods,
  mustHavePeriods,
  mustHavePeriodsNcaa,
  activePeriods,
  livePeriods,
  ncaaLivePeriods,
  getLivePeriods,
  PeriodAbbreviations,
  NcaaPeriodAbbreviations,
  PeriodLabels,
  NcaaPeriodLabels,
  isPrematch,
  isLive,
  getPeriodTime,
  getPeriodLabel,
  getPeriodValue,
  getDetails,
  updateEvent,
  formatPeriodTime,
  formatPeriodMinute,
  formatPeriodLabel,
  formatPeriodAbbreviation,
  formatPeriod,
  calculatePeriodProgress,
  createStatisticsConfiguration,
  getAvailablePeriods,
  getPeriodIndex,
  sortPeriods,
  createPlayerStatisticsColumns,
};
